<template>
    <b-container>
        <b-row class="text-center mb-3">
            <b-col cols="12">
                <h1>Accompagnement au sommeil</h1>
            </b-col>
        </b-row>
        <b-row align-v="center">
            <b-col xs="12" lg="6">
                <p>
                    <br class="d-none d-lg-block" />
                    Je vous propose un accompagnement personnalisé pour vous
                    permettre de comprendre le sommeil de votre bébé. Nous
                    parlerons des particularités du sommeil d'un nouveau-né, des
                    règles de sécurité pour le couchage, de l'endormissement,
                    des changements de rythmes, de la routine…<br />
                    <br />
                    Je ne propose pas de mode d'emploi, ni de solution miracle
                    mais je vous aiderai à suivre les besoins de votre enfant
                    sans oublier les vôtres.<br />
                    <br />
                    <div id='price'>
                        <hr />
                        <p>
                            Tarifs<br />
                            Consultation - 50€*<br />
                            Consultation et suivi - 80€*<br />
                        </p>
                        <hr />
                        <em>
                            * des frais peuvent s'ajouter en fonction de la localisation de votre domicile.
                        </em>                                
                    </div>
                </p>
            </b-col>
            <b-col xs="12" lg="6">
                <non-clickable-img url="sleep.jpg" />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import Price from '../components/Price';
import NonClickableImg from '../components/NonClickableImg';

export default {
    name: 'Sleep',
    components: { Price, NonClickableImg },
};
</script>

<style scoped>
p {
    text-align: justify;
    text-justify: inter-word;
}

#price p {
    color: #fff;
    background-color: var(--secondary);

    font-weight: bold;

    text-align: center;
}

#price em {
    font-size: 12px;
}
</style>
